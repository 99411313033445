import styles from './SelfRegNote.module.css'
import picOne from '../../Img/SelfAct/n11.svg'
import picTwo from '../../Img/SelfAct/a17.svg'

import picGu from '../../Img/SelfAct/gu.png'
import picBm from '../../Img/SelfAct/bm.png'
import picSim from '../../Img/SelfAct/sim.png'
import picArrow from '../../Img/SelfAct/arrow.png'
import picMail from '../../Img/SelfAct/mail.png'
import picDoc from '../../Img/SelfAct/doc.png'
import picNot from '../../Img/SelfAct/not.png'
import picSnils from '../../Img/SelfAct/snils.png'



import React, {useState} from "react";
import msTg from '../../Img/Messanger/tg.png'
import WaitLoader from "../../WaitLoader/WaitLoader";

interface SelfRegNotePropsInt
{
    country?:string
    ctn?: string
    cbExecute?: (code:number)=>void
}

export default function SelfRegNote({country,ctn,cbExecute}:SelfRegNotePropsInt)
{
    const [stepList, setStepList] = useState([
        {
            img: picGu,
            name: "Зарегистрироваться на портале Госуслуг РФ",
            dropped: false
        },
        {
            img: picBm,
            name: "Сдать биометрию в ЕБС",
            dropped: false
        },
        {
            img: picSim,
            name: "Активировать Вашу SIM-карту на портале Госуслуг",
            dropped: false
        },



    ])


    const showSubStep = (step: number) =>{
        return(
            <div className={styles.stepCont}>
                {step === 2 &&
                    <div>
                        <div className={styles.stepLine}>
                            <div className={styles.stepImgArr}><img src={picArrow}/></div>
                            <div>
                                <div>Войдите в Ваш аккаунт на Госсуслугах, выберите заявление, введите {ctn ? "номер " + ctn : "Ваш номер"} и подтвердите его по биометрии. Просто следуйте указаниям</div>

                                <div><a href={"https://www.rustore.ru/catalog/app/ru.rostel"} target="_blank">Ссылка на приложение Госсуслуги</a></div>
                                <div><a href={"https://www.gosuslugi.ru/655871/1/form"} target="_blank">Ссылка на заявление</a></div>

                            </div>

                        </div>
                    </div>

                }
                {step === 1 &&
                    <div>
                        <div className={styles.stepLine}>
                            <div className={styles.stepImgArr}><img src={picArrow}/></div>
                            <div>Используя документы полученные на первом шаге, сдать биометрию в любом банке, и так же там можно создать учетную запись на Госсуслугах</div>
                        </div>
                    </div>

                }

                {step === 0 &&
                    <div>
                        <div className={styles.stepLine}>
                            <div className={styles.stepImgArr}><img src={picMail}/></div>
                            <div>Создать новый или использовать свой почтовый ( e-mail ) ящик</div>
                        </div>
                        <div className={styles.stepLine}>
                            <div className={styles.stepImgArr}><img src={picDoc}/></div>
                            <div>Взять Ваш документ (паспорт), на который Вы оформили SIM карту</div>
                        </div>
                        <div className={styles.stepLine}>
                            <div className={styles.stepImgArr}><img src={picNot}/></div>
                            <div>
                                <div>Нотариально заверить перевод Вашего документа (паспорта) на русский язык</div>
                                <div><a href={"https://data.notariat.ru/directory/notary/?yqrid=xDdIwmPEaFH"} target="_blank">Список нотариусов в РФ</a></div>

                            </div>

                        </div>
                        <div className={styles.stepLine}>
                            <div className={styles.stepImgArr}><img src={picSnils}/></div>
                            <div>
                                <div>Получить СНИЛС у работодателя, МФЦ, СФР, по документам полученным в пунктах Выше</div>
                                <div><a href={"https://xn--d1achjhdicc8bh4h.xn--p1ai/search/mfc?yqrid=vovIwitexwY"} target="_blank">Список МФЦ в РФ</a></div>
                                <div><a href={"https://sfr.gov.ru/grazhdanam/social_fond/~8333?yqrid=8G5GfoD5cPo"} target="_blank">Список СФР в РФ</a></div>
                            </div>

                        </div>
                        <div className={styles.stepLine}>
                            <div className={styles.stepImgArr}><img src={picArrow}/></div>
                            <div>
                                <div>Зарегистрировать учетную запись на Госсуслугах, можно в МФЦ или любом банке</div>
                                <div><a href={""}>Список МФЦ в РФ</a></div>
                            </div>
                        </div>


                    </div>




                }
            </div>
        )

    }


    const handleClick = (id:number)=>{
        console.log("clicked")
        setStepList( stepList.map((item,idx)=>{

            if(idx === id)item.dropped = !item.dropped
            else item.dropped = false
            return item

        }))
    }

    const buildBlock = () =>{
        return stepList.map((item,idx) =>{
            return(
                <div key={idx}>
                    <div  className={[styles.blItem, item.dropped ? styles.actItem : ""].join(' ')}
                         onClick={(e)=>handleClick(idx)}
                    >
                        <div className={styles.blItemNum}>{idx+1}</div>
                        <div className={styles.blItemPic}>
                            {item.img && <div><img src={item.img}></img></div>}
                        </div>
                        <div className={styles.blItemText}>
                            {item.name}
                        </div>
                        <div className={styles.blItemArrow}>как сделать</div>
                    </div>
                    {item.dropped && showSubStep(idx)}

                </div>
            )

        })

    }

    const handleBot = () =>{
        window.open("https://t.me/bln_pmsh_bot","_blank")
    }

    const handleBack = ()=>{
        if(cbExecute)cbExecute(0)
    }

    return(
        <div className={styles.pdCont}>
            <div className={["row g-3 mb-1 "].join(' ')}>
                <div className={["col-md-12"].join(' ')}>
                        <div className={styles.pmTitle}>
                            Поздравляем, У Вас уже есть оформленная на Вас сим карта  { ctn ? "с номером +7"+ctn : ""},
                            теперь по приезду в Россию Вам осталось сделать всего три шага, представленные ниже. Кликните по каждому пункту для детальной информации
                        </div>
                </div>
            </div>

            <div className={["row g-3 mb-1 "].join(' ')}>
                <div className={["col-md-12"].join(' ')}>
                    <div className={styles.botCont}
                        onClick={()=>handleBot()}
                    >
                        <div className={styles.pmTitle}>
                            <img className={[styles.msCont, styles.fMsg].join(' ')} src={msTg} ></img>Можно воспользоваться ботом telegram <img className={styles.msCont} src={msTg}></img>
                        </div>

                    </div>
                </div>
            </div>


            <div className={["row g-3 mb-3 w-100"].join(' ')}>
                <div className={["col-md-12"].join(' ')}>
                    <div className={styles.blItenCov}>
                        <div className={styles.blItemCont}>
                            {buildBlock()}
                        </div>

                    </div>
                </div>
            </div>


            <div className={styles.btnRow}>
                <div className={styles.btnCont}>
                    <button type="button" className="btn btn-primary"
                            onClick={()=> handleBack()}
                    >Назад</button>

                </div>

            </div>


            <div className={["row g-3 mb-1 "].join(' ')}>
                    <div className={["col-md-4"].join(' ')}>
                        <div className={styles.ntItem}>
                            <div className={styles.ntLine}>
                                <img className={styles.ntImg} src={picOne}></img>
                            </div>
                            <div className={styles.ntCapt}>С 1 января 2025 г.</div>
                            <div>Для оформления потребуется подтверждение по биометрии</div>
                        </div>
                    </div>

                    <div className={["col-md-4"].join(' ')}>
                        <div className={styles.ntItem}>
                            <div className={styles.ntLine}>
                                <img className={styles.ntImg} src={picTwo}></img>
                            </div>
                            <div className={styles.ntCapt}>До 1 июля 2025 г.</div>
                            <div>Нужно переоформить купленные ранее сим-карты</div>
                        </div>
                    </div>

                    <div className={["col-md-4"].join(' ')}>
                        <div className={styles.ntItem}>
                            <div className={styles.ntLine}>
                                <img className={styles.ntImg} src={picTwo}></img>
                            </div>
                            <div className={styles.ntCapt}>До 10 сим-карт</div>
                            <div>Можно оформить на одного человека</div>
                        </div>
                    </div>



                </div>
        </div>
    )
}