import React, {useState} from 'react';
import styles from "./app.module.css"
import MainMenu from "./MainMenu/MainMenu";
import Header from "./Header/Header";
import SecondMenu from "./SecondMenu/SecondMenu";
import Slider from "./Slider/Slider";
import ModalWindow from "./ModalWindow/ModalWindow";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginForm from "./LoginForm/LoginForm";
import RegionChange from "./RegionChange/RegionChange";
import {useSelector} from "react-redux";
import {ReduxState} from "./Reducer/GlobalReducer";
import Lk from "./LK/LK";
import TariffFilter from "./TarifFilter/TariffFilter";
import FullCard from "./Slider/FullCard";
import OrderSimCard from "./OrderSimCard/OrderSimCard";
import {BiWebcam} from "react-icons/bi";
import SimPersonalData from "./SimRegistrator/PersonalData/SimPersonalData";
import SimSelfRegister from "./SimRegistrator/SimSelfRegister";
import InputItem from "./FormTemplate/InputItem/InputItem";
import GroupDataItem from "./FormTemplate/GroupDataItem/GroupDataItem";
import Footer from "./Footer/Footer";
import DeliveryItem from "./DeliveryItem/DeliveryItem";
export const AppContext = React.createContext<any | undefined>(undefined);
declare function CDEKWidget_(param: string): void;

const onlySelfReg = true

function App() {
    const [isOpen, setIsOpen] = useState(false);
    const authToken = useSelector((state:ReduxState)  => {return state.authToken})
    const isAdmin = useSelector((state:ReduxState)  => {return state.isAdmin})

    const selectRegion = useSelector((state:ReduxState)  => {return state.baseRegion.makeSelectRegion})


    const foreignPage = () =>{
        return(
            <div className={"container-fluid no-gutters " + styles.Wrapper}>
                <Routes >
                    <Route path='/self' element={<SimSelfRegister/>} />
                </Routes>
            </div>
        )
    }

    const mainPage = () =>{
       return(
        <div className={"container-fluid no-gutters " + styles.Wrapper}>
            <div className={"row-fluid"}>
                <Header/>
            </div>

            <div className={styles.mainContent}>
                <div className={"row-fluid"}>
                    <MainMenu/>
                </div>
                {selectRegion && <RegionChange />}
                <div className={"row-fluid"}>
                    <SecondMenu/>
                </div>

                <div className={"row-fluid"} id={"mainContainer"}>
                    <Routes >
                        <Route path='/lk/*' element={<Lk/>} />
                        <Route path='/auth' element={<LoginForm/>} />
                        <Route path='/' element={<Slider width={"100%"} height={"100%"} autoPlay={false} autoPlayTime={1000}/>}/>
                        <Route path='/order' element={<OrderSimCard/>} />
                        <Route path='/cdek' element={<DeliveryItem/>} />
                    </Routes>
                </div>


            </div>


            <div className={"row-fluid"}>
                <Footer/>
            </div>
        </div>)
    }


    if(onlySelfReg)
        return (
            <BrowserRouter  basename='/' >
                <Routes >
                    <Route path='/*' element={<SimSelfRegister/>} />
                </Routes>
            </BrowserRouter>

        )

    return (
        <BrowserRouter  basename='/' >
                <Routes >
                    <Route path='/act/*' element={foreignPage()} />
                    <Route path='/*' element={mainPage()} />
                </Routes>
        </BrowserRouter>
    );
}

export default App;
