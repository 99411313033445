import styles from './SimInfo.module.css'
import SelectUnit from "../../FormTemplate/SelectUnit/SelectUnit";
import React, {useEffect, useRef, useState} from "react";
import simImg from '../../Img/Pass/simIcc.png'
import useFormValidator, {FormValidatorInt} from "../../FormValidator/FormValidator";
import useApiQuery from "../../ApiQuery/UseApiQuery";
import WaitLoader from "../../WaitLoader/WaitLoader";
import tdkImg from "../../Img/Pass/tdk.png";
import uzbImg from "../../Img/Pass/uzb.png";
import kgzImg from "../../Img/Pass/kgz.png";

export interface SimInfoResultInt
{
    ctn:string
    icc:string
    code:number
}

interface SimInfoCbInt
{
    (data: SimInfoResultInt ):void
}
interface SimInfoPropsInt
{
    cbExecute: SimInfoCbInt
}

export default function SimInfo({cbExecute}:SimInfoPropsInt)
{

    const normCtn = (val: string)=>{
        //console.log(val)
        if(! val) return ""
        const ms = val.match(/(\+7|8|^|7)9[0-9]{9}$/ui)
        //console.log(ms)
        if(ms)
        {
            const k = val.match(/[0-9]{10}$/ui)
            return k ? "+7"+k[0] : ""
        }
        return val.replace(/[^0-9+]/uig,"")
    }
    const normCode = (val: string)=>{
        if(! val) return ""
        return val.replace(/[^0-9]/uig,"")
    }

    const [stage, setStage] = useState(0)
    const [error, setError] = useState({
        isError : false,
        text:""
    })
    const [formData,setFormData]=useState<FormValidatorInt[]>([
        {
            val:"",
            id:"ctn",
            errText: "Не корректный номер",
            regExp: /9[0-9]{9}$/ui,
            isCheck: true,
            valid: true,
            normalize: normCtn,
        },
        {
            val:"",
            id:"code",
            errText: "Не корректый код проверки",
            regExp: /^[0-9]{5,}$/ui,
            isCheck: true,
            valid: true,
            normalize: normCode,

        }])
    const [validate,validateById,getValue,setValue,getErrText,getItem] = useFormValidator(formData)
    const [lockForm, setLockForm] = useState(false)

    const simFindData = useRef(
        {
            ctn:"",
        }
    )
    const [ldrSimFind,rsSimFind,exSimFind] = useApiQuery("/api/application/sim/find","post",simFindData);


    const simCheckData = useRef(
        {
            ctn:"",
            code:""
        }
    )
    const [ldrSimCheck,rsSimCheck,exSimCheck] = useApiQuery("/api/application/sim/check","post",simCheckData);

    const [canvasData, setCanvasData] = useState<ImageData>()
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(()=>{
        loadImage().then()
    },[])

    useEffect(()=>{
        switch (stage)
        {
            case 0:
                setLockForm(false)
                break
            case 1:
                setLockForm(true)
                break
            default:
                setLockForm(false)
        }

    },[stage])


    const handleCheckData = () =>{
        if(! validate()) return;
        const mt = getValue('ctn').match(/[0-9]{10}$/ui)
        const ctn = mt ? mt[0] : null
        const code = getValue('code')
        if(ctn?.match(/9025536678/ui) || code?.match(/47122/ui))
        {
            setError({
                isError: true,
                text: "Введите данные Вашей SIM карты, а не данные с картинки"
            })
            return;
        }

        if( !ctn) return
        simCheckData.current =
            {
                code: code,
                ctn: ctn
            }
        setStage(1)
        exSimCheck().then((e)=>{
            if(e.success !== undefined && e.success)
            {
                const cnt = e?.payload?.data?.count
                switch (e?.payload?.code)
                {
                    case 0:
                        cbExecute({
                            ctn: e?.payload?.data?.simItem?.CTN,
                            icc: e?.payload?.data?.simItem?.ICC,
                            code: 0

                        })
                        return
                    case 1:
                        setStage(2)
                        setError({
                            isError: true,
                            text: "Ваша SIM карта не может быть зарегистрирована"
                        })
                        return;

                }




                setStage(2)
                setError({
                    isError: true,
                    text: e?.payload?.errorAsString ? e?.payload?.errorAsString : "SIM карта не может быть зарегистрирована"
                })
                return;
            }
            setStage(2)
            setError({
                text: "Сервер активации не доступен, попробуйте позже",
                isError: true
            })
            return
        })
    }

    const handleNote = () =>{
        cbExecute({
            ctn:"",
            code:1,
            icc:""
        })
        if(! validateById('ctn'))
        {
            setError({
                isError: true,
                text: "Введите номер для получения инструкций"
            })
            return
        }
        const fCtn = getValue('ctn')?.match(/[0-9]{10}$/ui)
        if( ! fCtn ) return;
        const ctn = fCtn[0]

        simFindData.current = {
            ctn: ctn
        }
        exSimFind().then((e)=>{
            console.log(e)
            if(e?.success !== undefined && e.success && e?.payload?.code !==undefined)
            {
                const code = e?.payload?.code
                if(code === 0)
                {
                    cbExecute({
                        code: 1,
                        ctn: ctn,
                        icc: ""
                    })
                    return
                }

                if(code === 2)
                {
                    setError({
                        isError: true,
                        text: 'Что бы получить справку, сначала активируйте номер, заполните все поля и нажмите кнопку далее'
                    })
                    return;

                }


                setError({
                    isError: true,
                    text: "Ваш номер не может быть использован для активации и получения справки"
                })


            }

        })

    }

    async function loadImage()
    {
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if(! context || !canvas) return
        context.clearRect(0,0,canvas.width,canvas.height)
        const img = new Image()
        context.globalAlpha = 0.9
        img.src = simImg
        if(img.src)
            return new Promise((resolve)=>{
                img.onload = ()=>{
                    canvas.height = img.height
                    canvas.width = img.width
                    context?.drawImage(img,0,0)

                    setCanvasData(context.getImageData(0,0,canvas.width ,canvas.height))
                    resolve(true)
                }
                img.onerror = ()=>resolve(false)

            })
    }

    const drawRect = (ctx: CanvasRenderingContext2D,x:number,y:number,dx:number,dy:number) =>{
        if(canvasData) ctx.putImageData(canvasData,0,0)
        ctx.strokeStyle = "#ff0001"
        ctx.lineWidth = 3
        ctx.strokeRect(x,y,dx,dy)
    }

    const drawNumber = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if(! context || !canvas ) return
        drawRect(context,158,333,285,35)

    }

    const drawCode = ()=>{
        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');
        if(! context || !canvas ) return
        drawRect(context,368,136,134,40)

    }


    return(
        <div className={styles.smCont}>
            <div className={styles.formView}>
                <form >
                    <div className="row g-3 mb-1">
                        <p className={styles.simTitle}>Введите в поля ниже - Ваш номер и проверочный код, проверочный код это <span>последние пять</span>  цифр в штрих коде на Вашей карте</p>
                    </div>
                    <div className="row g-3 mb-1">
                        <div className={styles.passCanvasCont}>
                            <canvas  className={styles.passCanvas} ref={canvasRef}/>

                        </div>
                    </div>


                    <div className="row g-3 mb-1">
                        <div className="col-md-6">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Ваш номер</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XXXXXXXXXX"}
                                   value={getValue("ctn")}
                                   onBlur={()=>validateById("ctn")}
                                   onChange={(e)=>setValue("ctn",e.target.value)}
                                   maxLength={12}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}
                                   onFocus={drawNumber}
                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("ctn")}</div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputEmail4" className="form-label col-form-label-sm">Ваш проверочный код (5 цифр)</label>
                            <input type="text" className="form-control form-control-sm" id="inputEmail4" placeholder={"XXXXX"}
                                   value={getValue("code")}
                                   onBlur={()=>validateById("code")}
                                   onChange={(e)=>setValue("code",e.target.value)}
                                   maxLength={12}
                                   inputMode={"numeric"}
                                   readOnly={lockForm}
                                   onFocus={drawCode}
                            >

                            </input>
                            <div  className={["", styles.errMessage].join(' ')}>{getErrText("code")}</div>
                        </div>

                    </div>

                    { error.isError &&
                        <div className="row g-3 mb-1">
                        <div className="col-md-12">
                            <p className={styles.errCont}>{error.text}</p>
                        </div>
                    </div>
                    }
                    <div className={styles.btnRow}>
                        <div className={styles.btnCont}>
                            <button type="button" className="btn btn-primary"
                                    onClick={()=> handleCheckData()}
                                    disabled={lockForm}
                            >Активировать</button>
                            <button type="button" className="btn btn-primary"
                                    onClick={()=> handleNote()}
                            >Инструкция</button>
                            <WaitLoader loading={ldrSimCheck || ldrSimFind} api={rsSimCheck} onlyLoading={true}>

                            </WaitLoader>

                        </div>

                    </div>




                </form>
            </div>
        </div>

    )
}
